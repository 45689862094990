import '../css/main.scss';
import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import "../js/magnificpopup";
import "../js/bootstrap";
import "../js/fontawesome";
import "../js/glide";

import UIBindings from "./ui-bindings";
import { Forms } from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";
import { ShareHandler } from "./share";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();

const forms = new Forms();
forms.validate();

const UIBinding = new UIBindings();
UIBinding.formSubmitButton();
