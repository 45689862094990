import $ from "jquery";
import "magnific-popup";
(function() {
    let image = $('[mfp-type="image"]');

    if (image.length > 0) {
        image.magnificPopup({type: "image"});
    }

    let gallery = $('[mfp-type="gallery"]');
    if (gallery.length > 0) {
        gallery.magnificPopup({
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: true,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                tCounter: '%curr% of %total%',
                preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
            },
            zoom: {
                enabled: true,
                duration: 500 // don't foget to change the duration also in CSS
            }
        });
    }

    let iframe = $('[mfp-type="iframe"]'); 

    if (iframe.length > 0) {
        iframe.magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    }

})();